import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Observable, of } from 'rxjs';
import { Subject } from 'rxjs';
import 'rxjs';
import { map, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MyAppHttpService } from './my-app-http.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { catchError } from 'rxjs/operators';
import { DataStorageService } from '../data-storage.service';
//import { MatDialog } from '@angular/material';
// import { catchError } from 'rxjs/internal/operators/catchError';
// import { InfoDialogComponent } from '../../views/app-dialogs/info-dialog/info-dialog.component';
// import Utils from 'src/app/services/common/utils';

declare var jsSHA: any;

@Injectable()
export class SendReceiveService {
    scheduleinterview_not_reload:boolean = false
    confirmationpopupvalues:boolean
    reskillingemployeesdata:any = []
    searching_implementation_for_profiles:any = []
    searchoptionsforfinprofile:any
    private _sequence: number = 1;
    globalsearchhelper:any = []
    searchDateList:any = []
    scheduledatehideshow:boolean = true
    myAppUrl: string = environment.url;
    myAppPythonUrl: string = environment.pythonurl;
    header: HttpHeaders;
    accessToken: any = '';
    public httpVerb = { 'Get': 'Get', 'Post': 'Post', 'Put': 'Put', 'Delete': 'Delete' };
    backflag1: boolean = false;

    private HTTP_REQUEST_PARAMS = {
        HEADER_CONTENT_TYPE: { value: "application/json; charset=UTF-8", valueContains: "application/json;" }
    };

    // Resolve HTTP using the constructor private dialog: MatDialog
    constructor(private http: Http, private _http: HttpClient, private router: Router, private snackBar: MatSnackBar,
        private dataStorage: DataStorageService) { }

    onInit() {
    }


    public send(method = null, apiUrl = null, data = null, mwType = null): Observable<any> {
        try {
            var mwURL = null;
            if (mwType == MyAppHttpService.MWType.PYTHON) {
                mwURL = this.myAppPythonUrl + apiUrl;
            }
            else if (mwType == MyAppHttpService.MWType.CHAT) {
                mwURL = environment.chatListURL + apiUrl;
            }
            else {
                mwURL = this.myAppUrl + apiUrl;
            }
            this.accessToken = localStorage.getItem('token');

            if (!!this.accessToken) {
                if (mwType == MyAppHttpService.MWType.PYTHON) {
                    this.header = new HttpHeaders({
                        'Content-Type': 'application/json'
                    });
                }
                else {

                    if (apiUrl == 'uploadResume') {
                        this.header = new HttpHeaders({
                            'Content-Type': undefined,
                            'token': this.accessToken
                        });
                    }
                    else {
                        this.header = new HttpHeaders({
                            'Content-Type': 'application/json',
                            'token': this.accessToken
                        });
                    }

                }
                // this.header = new HttpHeaders({
                //     'Content-Type': 'application/json',
                //     'token': this.accessToken
                // });
            } else {
                if (mwType == MyAppHttpService.MWType.CHAT) {
                    this.header = new HttpHeaders({
                        'Content-Type': 'application/json'
                    });
                }
                else {
                    this.header = new HttpHeaders({
                        'Content-Type': 'application/json',
                        'token': 'anonymous'
                    });
                }
                // let headers = new HttpHeaders();
                // headers = headers.set('Accept', 'application/zip');
                // return this.http.get('https://s3.ap-south-1.amazonaws.com/ribucketdev/9739979087/1597/audio/9739979087_audio_RI_Renuka_Reddy_14-Nov-2018.mp4', { headers: headers, responseType: 'blob' });
            }
            // if (!!this.accessToken)
            //     this.header.append('token', this.accessToken);
            //if(apiUrl != 'shareProfile'){
                if(apiUrl == "addToJobs"){
                    setTimeout(() => {
                        document.getElementById('preloader-div').style.display = 'block';    
                    }, 1000);
                }
                document.getElementById('preloader-div').style.display = 'block';
                
            
            //}
            if (method == this.httpVerb.Get) {
                return this._http.get<any>(mwURL, { headers: this.header, observe: 'response' })
                    .pipe(map((response) => {
                        setTimeout(() => {
                            document.getElementById('preloader-div').style.display = 'none';
                        }, 1000);
                        console.log('response url: ', response.url);
                        if (response.url.indexOf(MyAppHttpService.REQUESTS.GetProbabilityOfJoiningValue) == -1) {
                            localStorage.setItem('token', !!response.headers.get('token') ? response.headers.get('token') : 'anonymous');
                        }
                        return response.body;
                    }, (error) => {
                        setTimeout(() => {
                            document.getElementById('preloader-div').style.display = 'none';
                        }, 1000);
                        console.log(error);
                    }), catchError(this.errorHandler)
                    );

                //catchError(this.errorHandler);

            }
            else if (method == this.httpVerb.Post) {
                return this._http.post<any>(mwURL, data, { headers: this.header, observe: 'response' })
                    .pipe(map((response) => {
                        setTimeout(() => {
                            document.getElementById('preloader-div').style.display = 'none';
                        }, 1000);
                        if (response.url.indexOf(MyAppHttpService.REQUESTS.GetProbabilityOfJoiningValue) == -1 && mwType != MyAppHttpService.MWType.CHAT) {                            
                            localStorage.setItem('token', !!response.headers.get('token') ? response.headers.get('token') : 'anonymous');
                        }
                        return response.body;
                    }, (error) => {
                        setTimeout(() => {
                            document.getElementById('preloader-div').style.display = 'none';
                        }, 1000);
                        console.log(error);
                    }), catchError(this.errorHandler));
                // catchError(this.errorHandler)
            }
            else if (method == this.httpVerb.Put) {
                return new Observable;
            }
            else if (method == this.httpVerb.Delete) {
                return new Observable;
            }
            else { }


        } catch (e) {
            //Utils.logMessage("Error", e);
        }
    }


    public logoutService() {
        this.send(this.httpVerb.Get, MyAppHttpService.REQUESTS.Logout).subscribe((success => {
            // localStorage.removeItem('token');
            // localStorage.removeItem('userData');
            if (success.statuscode = MyAppHttpService.RESPONSE_CODES.SUCCESS) {
                localStorage.clear();
                this.dataStorage.membershipCurrentPlan = {};
                this.dataStorage.globalGuestUserProfileData = { userId: 0, userType: '', userName: '' };
                this.dataStorage.globalChatUserInfo = {};
                this.dataStorage.globalAdminchatUserInfo = {};
                this.dataStorage.globalUserChatConversationList = {};
                this.dataStorage.globalAdminChatConversationList = {};
                this.dataStorage.loggedInUserData = {};
                this.dataStorage.loggedInUserName = '';
                this.dataStorage.isUserLoggedIn = false;
                this.dataStorage.previousSearchCriteria = {};

                this.dataStorage.globalAvailableFeatureInfo = JSON.parse(JSON.stringify(MyAppHttpService.AVAILABLE_FEATURE_INFO));
                this.dataStorage.globalUnread_ChatIdInfo = {adminChatId: '', userChatId: '', adminUnreadCount: 0, RIUnreadCount: 0, REUnreadCount: 0}
                this.router.navigate(['/login']);
            }
        }), error => {
            console.log('error', error);
            // this.myAppService.showAlertMessage(error.message);
        });
    }

    errorHandler(error: Response) {
        setTimeout(() => {
            document.getElementById('preloader-div').style.display = 'none';
        }, 1000);

        //console.log(error);
        // if(error.status  == 401 || error.status == 500){
        if (error.status == 401) {
            // debugger;

            localStorage.clear();
            // this.router.navigate(['/']);
            location.href = '/';
            return of(undefined);
        }
        return of(undefined);

    }

    showDialog(message) {
        // let dialogInstance = this.dialog.open(
        //     InfoDialogComponent, {
        //     data: {
        //       message: message  
        //     }
        //   });

        //   setTimeout(() => {
        //     dialogInstance.close();
        //   }, 5000);
    }

    globalSearch(searchInput:string,searchList:any,searchBy:Number)
    {
        // console.log(searchList)
        let tempList:any =[]
        tempList = searchList
        let key:any 
        let includesdates:any = []
        let mapresultsArray:any = [],searchResults:any=[]
        searchList.map((r) => {
            if(!mapresultsArray.includes(r))
              {
                  if(searchBy == 4 || searchBy == 5)
                  {
                     
                        if(r.profileDetails)
                        {
                          mapresultsArray.push(r.profileDetails[0])
                        }
                        else
                        {
                            if(searchInput.length == 0)
                            {
                                mapresultsArray.push(r)
                            }
                            else
                            {
                            }
                        }
                      
                      
                     // mapresultsArray.push(r.profileDetails[0])
                  }
                  else if(searchBy == 6)
                  {
                      //interviewCreatedDate: "2022-07-08T12:52:41.000+0000"
                      if(!!r.scheduleInterviewInfo)
                      {
                        // console.log(r.scheduleInterviewInfo[0].interviewCreatedDate)
                        if(!mapresultsArray.includes(r.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0]))
                        {
                            //this.sendreceiveservice.searchDateList
                            mapresultsArray.push(r.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0])
                        }

                      }
                  }
                  else if(searchBy == 10)
                  {
                      let createddate = r.createdDate.slice(0,11)
                      if(!mapresultsArray.includes(r.createdDate.slice(0,11)))
                        mapresultsArray.push(r.createdDate.slice(0,11))
                  }
                  else
                  {
                    mapresultsArray.push(r)
                  }
                  
              }
            if(true)  
            searchResults = mapresultsArray.filter((s) => {
                    
                   if(searchBy == 1)
                   try{
                       return s.emailId.toLowerCase().includes(searchInput.toLowerCase())
                    }
                   catch(e){
                       console.log(e)
                    // return s.profileDetails[0].emailId.toLowerCase().includes(searchInput.toLowerCase())
                    }
                    else if(searchBy == 7)
                    {
                        try{
                            return s.fullName.toLowerCase().includes(searchInput.toLowerCase())
                        }
                        catch(e)
                        {
                            console.log(e)
                        }
                        
                    }
                    else if(searchBy == 8)
                    {
                        try{
                            return s.email.toLowerCase().includes(searchInput.toLowerCase())
                        }
                        catch(e)
                        {
                            console.log(e)
                        }
                        
                    }
                    else if(searchBy == 9)
                    {
                        try{
                            return s.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())
                        }
                        catch(e)
                        {
                            console.log(e)
                        }
                        
                    }
                    // else if(searchBy == 10)
                    // {
                    //     return s//.fullName.toLowerCase().includes(searchInput.toLowerCase())
                    // }
                   else if(searchBy == 2)
                   try{
                    // if(!this.searchDateList.includes(s.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0]))
                    // {
                    //     //this.sendreceiveservice.searchDateList
                    //     this.searchDateList.push(s.scheduleInterviewInfo[0].interviewCreatedDate.split("T")[0])
                        
                    // }
                    // console.log(this.searchDateList)
                    
                    if(s.userName != undefined)
                    {
                        // console.log(s.userName)
                        // if(s.userName == "" || s.userName.length == 0){
                        //     console.log('nome not available')
                        // }
                        if(!!s.userName)
                        {
                            return s.userName.toLowerCase().includes(searchInput.toLowerCase())
                        }
                        else
                        {
                            // s.userName == 'Not Available'
                            // console.log('dee',s.userName)
                        }
                           
                    }
                    }catch(e){
                       
                        console.log(e)
    
                    }
                   else if(searchBy == 3)
                   try{
                       return s.scheduleInterviewInfo[0].mobilenumber_JS.toLowerCase().includes(searchInput.toLowerCase())}catch(e){
                       console.log(e)
                    }    
                    if(searchBy == 4)
                   {
                    // try{
                    if(searchInput.length == 0)
                      {
                        searchResults = tempList
                           return tempList
                      }
                      else
                      {
                       return s.emailId.toLowerCase().includes(searchInput.toLowerCase())
                      }
                    }
                    else if(searchBy == 5)
                    // try{
                        if(searchInput.length == 0)
                      {
                        searchResults = tempList
                          
                           return tempList
                      }
                      else
                      {
                     return s.mobileNumber.toLowerCase().includes(searchInput.toLowerCase())
                      } 
                   else{
                    return tempList
                   } 
                })
        })    
        // console.log(searchResults[0].isProfileDisabled)
        if(searchResults.length == 0)
        {
            // this.searchDateList[0] = false//'No Date Found'
            // console.log(searchResults)
            this.scheduledatehideshow = false
        }
        else if(searchResults.length != 0)
        {
            // this.searchDateList[0] = true
            this.scheduledatehideshow = true
        }
        else if(searchResults[0].isProfileDisabled)
        {
            // this.searchDateList[1] = false//searchResults[0].isProfileDisabled
        // console.log(searchResults)
        }
        // console.log(searchResults)
        return searchResults
    }

}

