import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataStorageService } from 'src/app/services/data-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jobinfo-modal',
  templateUrl: './jobinfo-modal.component.html',
  styleUrls: ['./jobinfo-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JobinfoModalComponent implements OnInit {

  jobDetails: any;
  jobType: any = '';
  workRemote: false;
  workFromOffice: false;
  hybrid: false;
  onSite: false;
  countries: any = [];
  states: any = [];
  cities: any = [];
  countries122: any = [];
  states122: any = [];
  cities122: any = [];
  jobFilter = 1;

  constructor(public dialogRef: MatDialogRef<JobinfoModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  public router: Router) {
    // console.log('Job data : ', data.jobDetails);
  }

  ngOnInit() {
    this.jobDetails = this.data.jobDetails;
    this.jobDetails[0].location = this.jobDetails[0].location.replace(/,/gi, ', ') + ', India';
    this.jobType = this.jobDetails[0].jobType.includes('/') ?
                   this.jobDetails[0].jobType.split('/')[0] : this.jobDetails[0].jobType;
    this.jobDetails[0].countries = this.jobDetails[0].countries.filter((c: any) => c != 'India');
  }

  returnSkillsArray(skills) {
    return  skills ? skills.split(',') : [];
  }

  formatUrl(url){
   
    if(url){
      if (!url.match(/^https?:\/\//i)) {
        url = 'http://' + url;
      }
      return url;
    }
    else{
      return '';
    }
  }

}
